import { ReactNode } from 'react';
import { orderItemByKeyWithPrice_orderItemByKey } from '../types/generated/orderItemByKeyWithPrice';
import { DutyName } from '../types/graphql-global-types';

export enum NonDutyFeature {
  advice = 'advice',
  adviceOnLocation = 'adviceOnLocation',
  quotes = 'quotes',
  coordination = 'coordination',
  invoicing = 'invoicing',
  warranty = 'warranty',
  updates = 'updates',
  unlimitedMoves = 'unlimitedMoves',
  energyLabelSupplementary = 'energyLabelSupplementary',
  energyLabelAdvice = 'energyLabelAdvice',
  currentLabelRegistration = 'currentLabelRegistration',
  improvedLabelRegistration = 'improvedLabelRegistration',
  aftercare = 'aftercare',
}

type PackageFeatureObj = Record<DutyName | NonDutyFeature, PackageCardItem>;

export const packageFeatures: PackageFeatureObj = {
  virtualVisit: {
    key: DutyName.virtualVisit,
    label: <>Direct online inzicht in alle mogelijke duurzame oplossingen</>,
    description: (
      <>
        Krijg digitaal inzicht in alle mogelijkheden voor je woning, inclusief terugverdientijden en
        een inschatting van de kosten.
      </>
    ),
    genericDescription: (
      <>
        Op korte termijn plannen we een woningopname op afstand. Onze adviseur brengt gehele woning
        in kaart via een videogesprek.
      </>
    ),
  },
  visit: {
    key: DutyName.visit,
    label: <>Persoonlijk contact met een energieadviseur</>,
    description: (
      <>
        Onze adviseur brengt de woning in kaart door uitgebreide inspectie en bespreekt direct de
        uitkomsten met de klant.
      </>
    ),
    genericDescription: (
      <>
        Onze adviseur brengt de woning in kaart door uitgebreide inspectie en bespreekt direct de
        uitkomsten met de klant.
      </>
    ),
  },
  advice: {
    key: NonDutyFeature.advice,
    label: <>Inzicht in mogelijkheden voor zonnepanelen, isolatie, glas en verwarming</>,
    description: (
      <>
        Energiebespaarders geeft onafhankelijk advies over veel verschillende energiebesparende
        maatregelen. Ook wordt gekeken welke oplossingen passen bij jouw woning en wensen.
      </>
    ),
    genericDescription: (
      <>
        Onze adviseur geeft onafhankelijk advies over álle energiebesparende maatregelen. Ook wordt
        gekeken welke oplossingen passen bij de woning en wensen van de klant.
      </>
    ),
  },
  adviceOnLocation: {
    key: NonDutyFeature.adviceOnLocation,
    label: <>Adviesgesprek op locatie</>,
  },
  quotes: {
    key: NonDutyFeature.quotes,
    label: <>Offertes van betrouwbare en gecertificeerde installateurs</>,
    description: (
      <>
        Jouw adviseur maakt offertes met uitsluitend de beste installatie-expert en het juiste
        product. De offertes vind je terug in jouw eigen account en kunnen rustig nagelezen worden.
      </>
    ),
    genericDescription: (
      <>
        Onze adviseur maakt offertes met uitsluitend de beste installatie-expert en het juiste
        product. De offertes kan de klant in zijn of haar account terugvinden en kunnen rustig
        nagelezen worden.
      </>
    ),
  },
  cashback: {
    key: DutyName.cashback,
    label: <>100% cashback op het servicepakket bij uitvoer van installatie(s)</>,
    description: (
      <>
        Neem je een of meerdere installaties bij ons af? Dan krijg je het volledige aankoopbedrag
        van het servicepakket terug!
      </>
    ),
    genericDescription: (
      <>
        Neemt de klant een of meerdere installaties bij ons af? Dan krijgt de klant het volledige
        aankoopbedrag van het servicepakket terug!
      </>
    ),
  },
  coordination: {
    key: NonDutyFeature.coordination,
    label: <>Coördinatie van installaties</>,
    description: (
      <>
        Neem je een of meerdere installaties bij ons af? Wij regelen de volledige installatie met
        onze betrouwbare, gecertificeerde installatiepartners.
      </>
    ),
    genericDescription: (
      <>
        Neemt de klant een of meerdere installaties bij ons af? Wij regelen de volledige installatie
        met onze betrouwbare, gecertificeerde installatiepartners.
      </>
    ),
  },
  invoicing: {
    key: NonDutyFeature.invoicing,
    label: <>Facturatie via Energiebespaarders</>,
    description: (
      <>
        Om het voor jou overzichtelijk te houden gaat de facturatie van alle installaties via De
        Energiebespaarders. Ook als er meerdere maatregelen worden geïnstalleerd.
      </>
    ),
    genericDescription: (
      <>
        Om het voor de klant overzichtelijk te houden gaat de facturatie van alle installaties via
        Energiebespaarders. Ook als er meerdere maatregelen worden geïnstalleerd.
      </>
    ),
  },
  warranty: {
    key: NonDutyFeature.warranty,
    label: <>Garantie op de installaties</>,
    description: (
      <>
        Je bent verzekerd van een uitstekende oplevering. Wij zorgen dat de installatie altijd van
        hoge kwaliteit is. Bij onvoorziene problemen of schade zorgen we dat het goed en snel wordt
        opgelost.
      </>
    ),
    genericDescription: (
      <>
        De klant is verzekerd van een uitstekende oplevering. Wij zorgen dat de installatie altijd
        van hoge kwaliteit is. Bij onvoorziene problemen of schade zorgen we dat het goed en snel
        wordt opgelost.
      </>
    ),
  },
  energiepaspoort: {
    key: DutyName.energiepaspoort,
    label: <>Volledig inzicht met ons uitgebreide Energiepaspoort</>,
    description: (
      <>
        Je ontvangt een persoonlijk, onafhankelijk Energiepaspoort rond de duurzaamheidssituatie van
        jouw woning. Het geeft inzicht in de huidige situatie en de besparing, kosten en
        terugverdientijd van verschillende mogelijke installaties. Ook bevat het een interactieve
        berekeningstool om maatregelen tegen elkaar af te zetten. Zo heb jij een rapport dat je
        helpt de juiste keuzes te maken.
      </>
    ),
    genericDescription: (
      <>
        De klant ontvangt een persoonlijk, onafhankelijk Energiepaspoort rond de
        duurzaamheidssituatie van de woning. Het geeft inzicht in de huidige situatie en de
        besparing, kosten en terugverdientijd van verschillende mogelijke installaties. Ook bevat
        het een interactieve berekeningstool om maatregelen tegen elkaar af te zetten.
      </>
    ),
    price: 69,
  },
  updates: {
    key: NonDutyFeature.updates,
    label: <>Onbeperkte updates van je offertes en advies</>,
    description: (
      <>
        Na de installaties kan het Energiepaspoort bijgewerkt worden om de nieuwe situatie met
        energiebesparende maatregelen in kaart te brengen. Met deze updates heb jij altijd een
        bijgewerkte weergave van de huidige situatie rond jouw woning.
      </>
    ),
    genericDescription: (
      <>
        Na de installaties kan het Energiepaspoort bijgewerkt worden om de nieuwe situatie met
        energiebesparende maatregelen in kaart te brengen. Met deze updates is er altijd een
        bijgewerkte weergave van de huidige situatie van de woning.
      </>
    ),
  },
  unlimitedMoves: {
    key: NonDutyFeature.unlimitedMoves,
    label: <>Verhuizing? Vrijblijvend opnieuw advies</>,
    description: (
      <>
        Ga je verhuizen naar een andere woning? Neem contact met ons op om ook voor je nieuwe woning
        kosteloos van onze service gebruik te maken. Zo blijf je altijd zo energiezuinig mogelijk
        wonen, waar je ook woont!
      </>
    ),
    genericDescription: (
      <>
        Verhuist de klant naar een andere woning? Dan kan de klant weer kosteloos gebruik maken van
        onze service.
      </>
    ),
  },
  energyLabel: {
    key: DutyName.energyLabel,
    label: <>Energielabel na installatie geregistreerd</>,
    description: (
      <>
        Wij melden het nieuwe energielabel van je woning voor je af bij de Rijksoverheid. Zo wordt
        een eventuele labelsprong meteen vastgelegd. Woningen met een hoger energielabel verkopen
        sneller en zijn gemiddeld meer waard. Bovendien kan een hoger label van invloed zijn op je
        hypotheekrente.
      </>
    ),
    genericDescription: (
      <>
        Wij melden het nieuwe energielabel van de woning voor de klant af bij de Rijksoverheid. Zo
        wordt een eventuele labelsprong meteen vastgelegd. Woningen met een hoger energielabel
        verkopen sneller en zijn gemiddeld meer waard.
      </>
    ),
  },
  subsidy: {
    key: DutyName.subsidy,
    label: <>Hulp bij subsidieaanvragen</>,
    description: (
      <>
        Er zijn vaak verschillende subsidies beschikbaar voor energiebesparende maatregelen. Via
        onze partner SimpelSubsidie kun je de subsidieaanvragen uit handen geven tegen een scherpe
        prijs.
      </>
    ),
    genericDescription: (
      <>
        Er zijn vaak verschillende subsidies beschikbaar voor verschillende energiebesparende
        maatregelen. Wij zoeken voor de klant uit welke subsidies er aangevraagd kunnen worden en
        regelen de aanvraag via een gespecialiseerde partner.
      </>
    ),
  },
  fullElectricAdvice: {
    key: DutyName.fullElectricAdvice,
    label: <>All-electric advies (naar aardgasvrij)</>,
    description: '',
  },
  nomAdvice: {
    key: DutyName.nomAdvice,
    label: <>NOM advies (nul-op-de-meter)</>,
    description: '',
  },
  energyIndex: {
    key: DutyName.energyIndex,
    label: <>Energie-Index / EPC opstellen</>,
    description: '',
  },
  taxation: {
    key: DutyName.taxation,
    label: <>Taxatie na verduurzaming</>,
    description: '',
  },
  quoteDiscount: {
    key: DutyName.quoteDiscount,
    label: <>Actiekorting op offertes</>,
    description: '',
  },
  aftercare: {
    key: NonDutyFeature.aftercare,
    label: <>Nazorg en financiële voordelen</>,
    description: (
      <>
        Voor isolatie en duurzame verwarming is subsidie beschikbaar. We ondersteunen bij de
        subsidieaanvraag.
      </>
    ),
  },
  energyLabelSupplementary: {
    key: NonDutyFeature.energyLabelSupplementary,
    label: <>Energielabel registreren bij Rijksoverheid</>,
    description: (
      <>
        Wij melden het nieuwe energielabel van je woning voor je af bij de Rijksoverheid. Zo wordt
        een eventuele labelsprong meteen vastgelegd. Woningen met een hoger energielabel verkopen
        sneller en zijn gemiddeld meer waard.
      </>
    ),
    genericDescription: (
      <>
        Wij melden het nieuwe energielabel van de woning voor de klant af bij de Rijksoverheid. Zo
        wordt een eventuele labelsprong meteen vastgelegd. Woningen met een hoger energielabel
        verkopen sneller en zijn gemiddeld meer waard.
      </>
    ),
    price: 150,
  },
  energyLabelAdvice: {
    key: NonDutyFeature.energyLabelAdvice,
    label: <>Uitgebreid digitaal adviesrapport met label-verbeter-advies</>,
    description: (
      <>
        Jouw adviseur geeft onafhankelijk advies over álle energiebesparende oplossingen. Er wordt
        gekeken welke oplossingen passen bij jouw woning en wensen. Daarnaast krijg je advies over
        welke oplossingen je nodig hebt om naar een bepaald label te gaan.
      </>
    ),
    genericDescription: (
      <>
        Onze adviseur geeft onafhankelijk advies over álle energiebesparende maatregelen. Ook wordt
        gekeken welke oplossingen passen bij de woning en wensen van de klant.
      </>
    ),
  },
  currentLabelRegistration: {
    key: NonDutyFeature.currentLabelRegistration,
    label: <>Registratie huidig energielabel</>,
    description: 'Indien gewenst wordt het huidige energielabel van de woning geregistreerd',
  },
  improvedLabelRegistration: {
    key: NonDutyFeature.improvedLabelRegistration,
    label: (
      <>
        Verbeterd label gratis geregistreerd bij uitvoer van installatie(s) door De
        Energiebespaarders
      </>
    ),
    description:
      'Registratie van het verbeterde label vindt alleen plaats wanneer de installaties via Energiebespaarders zijn uitgevoerd.',
  },
};

export function getPackageFeature(key: DutyName | NonDutyFeature | string): PackageCardItem {
  if (key in packageFeatures) {
    return packageFeatures[key as unknown as DutyName];
  } else {
    // console.warn('Key not present in PackageFeatures:', key);
    return { key: key as any, label: key, description: '' };
  }
}

export enum PartnerCompanyName {
  ASR = 'a.s.r',
  CompareWithIsa = 'Compare With Isa',
  FinancieelFit = 'Financieel Fit',
  Florius = 'Florius',
  GavoorA = 'GavoorA',
  HPHypotheken = '100% Hypotheken',
  HuisHypotheek = 'Huis & Hypotheek',
  Hypokeur = 'Hypokeur',
  Hypotheekshop = 'De Hypotheekshop',
  HypotheekVisie = 'Hypotheek Visie',
  JijEnWij = 'JIJENWIJ',
  Mangotree = 'Mangotree',
  MUNT = 'MUNT Hypotheken',
  NBG = 'NBG',
  OfferteAdviseur = 'OfferteAdviseur',
  OfferteNL = 'Offerte.nl',
  RISK = 'RISK',
  Slimster = 'Slimster',
  Solvari = 'Solvari',
  Triodos = 'Triodos Bank',
  Veldsink = 'Veldsink',
  Viisi = 'Viisi Hypotheken',
  Vista = 'Vista Hypotheken',
  VvAA = 'VvAA',
  Yonego = 'Yonego',
}

export type PackageCardItem = {
  key: DutyName | NonDutyFeature;
  label: ReactNode;
  description?: ReactNode; // Description aimed at customers
  genericDescription?: ReactNode; // Description not aimed at customer (e.g. at partnerAgent)
  price?: number;
};

export type DutyPackageWithPrice = orderItemByKeyWithPrice_orderItemByKey & {
  additions?: string[];
};

export type CustomDutyPackageProps = Partial<Readonly<Exclude<DutyPackageWithPrice, 'key'>>> & {
  logo?: string;
};
