import React from 'react';
import styled from 'styled-components';
import { Medium } from '@energiebespaarders/symbols/helpers';
import { shade, themify } from '../../styles/mixins';

interface StyledRibbonProps {
  $bgColor: string;
  $color: string;
  $new?: boolean;
  $right?: boolean;
}

const StyledRibbon = styled.div<StyledRibbonProps>`
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  top: ${x => (x.$new ? '-10px' : '-13px')};
  left: -10px;
  z-index: 1;

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid ${x => shade(0.85, x.$bgColor)};
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &:before {
    top: 0;
    right: 0;
  }

  &:after {
    bottom: 0;
    left: 0;
  }

  & > span {
    position: absolute;
    display: block;
    width: 180px;
    font-size: 0.75rem;
    padding: 15px 0;
    background-color: ${x => x.$bgColor};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: ${x => x.$color};
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-45deg);
    top: 18px;
    right: -14px;
  }
`;

const StyledRibbonRightSide = styled(StyledRibbon)`
  left: auto;
  right: -10px;

  &:before,
  &:after {
    border: 5px solid ${x => shade(0.85, x.$bgColor)};
    border-top-color: transparent;
    border-right-color: transparent;
  }

  &:before {
    top: 0;
    right: auto;
    left: 0;
  }

  &:after {
    bottom: 0;
    left: auto;
    right: 0;
  }

  & > span {
    transform: rotate(45deg);
    top: 18px;
    right: auto;
    left: -14px;
  }
`;

interface PackageRibbonProps {
  bgColor?: string;
  color?: string;
  text?: string;
  isNewPackageCard?: boolean;
  useRightSide?: boolean;
}

const PackageRibbon: React.FC<PackageRibbonProps> = ({
  bgColor = 'orange',
  color = 'white',
  text,
  isNewPackageCard,
  useRightSide,
}) => {
  const ribbonProps = {
    $bgColor: themify(bgColor),
    $color: themify(color),
    $new: isNewPackageCard,
  };
  return text ? (
    useRightSide ? (
      <StyledRibbonRightSide {...ribbonProps}>
        <Medium>{text}</Medium>
      </StyledRibbonRightSide>
    ) : (
      <StyledRibbon {...ribbonProps}>
        <Medium>{text}</Medium>
      </StyledRibbon>
    )
  ) : (
    <></>
  );
};

export default PackageRibbon;
