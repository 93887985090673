import { breakpointsPx } from '@energiebespaarders/symbols/styles/breakpoints';
import { useState, useCallback, useEffect } from 'react';

/** Returns true when current screen size is below a certain breakpoint */
const useMediaQuery = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | number) => {
  const [targetReached, setTargetReached] = useState(false);

  const width = typeof size === 'number' ? size : breakpointsPx[size];
  const updateTarget = useCallback((e: MediaQueryListEvent) => setTargetReached(e.matches), []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mql = window.matchMedia(`(max-width: ${width}px)`);

      if (mql.addEventListener) {
        mql.addEventListener('change', updateTarget);
      } else {
        // Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
        mql.addListener(updateTarget);
      }

      // Check on mount (callback is not called until a change occurs)
      if (mql.matches) setTargetReached(true);

      return () => {
        if (mql.removeEventListener) {
          mql.removeEventListener('change', updateTarget);
        } else {
          mql.removeListener(updateTarget);
        }
      };
    }
  }, [updateTarget, width]);

  return targetReached;
};

export default useMediaQuery;
